export const STATUS_ITEM = {
    pulse: 'Пульс',
    turns: 'Обороты',
    condition: 'Условие',
    time: 'Время'
}

export const CONDITION_TYPE = {
    standing: 'Стоя',
    sitting: 'Сидя'
}